import { ScreenText } from '@/lang/ScreenText';
import { Vue, Component, Prop } from 'vue-property-decorator';


@Component({
   components: {
   }
})
export default class ExportToCsv extends Vue {

   @Prop({default:false})
   isDownloadCsv!:boolean

   private objScreenText: ScreenText = new ScreenText();


   public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
   }

   downloadCsv() {
      this.$emit('downloadCsv');
   }

}